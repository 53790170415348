<template>
	<div style="text-align: center;padding: 30px 10px;">
		<van-icon name="warning" size="110" color="orange"/>
		
		<p style="margin: 20px 0 80px 0;">{{translate('login_has_expired')}}</p>
		
		
		<van-button type="danger" size="small" round @click="login()">{{translate('re_register')}}</van-button>
	</div>
</template>

<script>
	export default {
		name: 'nologin',
		methods: {
			login() {
				// 登录
				if (window.JsObject != null) {
					window.JsObject.login()
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"action": "login"})
				}
			},
		}
	}
</script>